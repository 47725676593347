import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import values from "../../utils/values"
import BackgroundImgWeb from "./BackgroundImgWorksWeb"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderWrap = styled.div`
  width: 280px;
  margin: 0 auto;

  @media (min-width: ${values.DEVICESIZE.mobileM}) {
    width: 340px;
  }

  @media (min-width: 540px) {
    width: 450px;
  }

  @media (min-width: ${values.DEVICESIZE.tablet}) {
    width: 600px;
  }

  @media (min-width: ${values.DEVICESIZE.laptopL}) {
    width: 850px;
  }

  @media (min-width: ${values.DEVICESIZE.desktop}) {
    width: 1200px;
  }

  .active-img-wrap {
    height: 160px;
    margin-bottom: 5rem;
    padding: 5px;

    & > .gatsby-image-wrapper {
      width: 78%;
      height: 90%;
      transform: translate(-50%, -50%);
      top: 47%;
      left: 50%;
      img {
        //border: 5px solid black;
        //border-radius: 5px;
        background-color: #fff;
        //padding: 2px;
      }
    }

    @media (min-width: ${values.DEVICESIZE.mobileM}) {
      height: 195px;
    }

    @media (min-width: 540px) {
      height: 250px;
    }

    @media (min-width: ${values.DEVICESIZE.tablet}) {
      height: 340px;
    }

    @media (min-width: ${values.DEVICESIZE.laptopL}) {
      padding: 10px 15px;
      height: 490px;
    }

    @media (min-width: ${values.DEVICESIZE.desktop}) {
      height: 690px;
    }
  }

  .slick-slider {
    .slick-list {
      width: 90%;
      margin: 0 auto;
    }

    .slick-track {
      display: flex;
      height: 100%;

      .slick-slide {
        flex: 1 0 auto;
        height: auto;

        & > div:first-child {
          display: flex;
          height: 100%;
        }
      }
    }
    .slick-arrow {
      z-index: 3;
      width: 30px;
      height: 30px;

      :before {
        color: black;
        font-size: 30px;
      }

      &.slick-next {
        right: -10px;
      }

      &.slick-prev {
        left: -10px;
      }

      @media (min-width: ${values.DEVICESIZE.laptop}) {
        width: 40px;
        height: 40px;

        :before {
          font-size: 40px;
        }
      }
    }
  }
`

const StyledItemContainer = styled.div`
  .gatsby-image-wrapper {
    margin: 0 auto;
    width: 70%;
    max-height: 150px;
    opacity: 0.85;
    border-radius: 5px;

    img {
      border: 2px solid dimgrey;
      border-radius: 5px;
    }

    @media (min-width: ${values.DEVICESIZE.tablet}) {
      width: 60%;
    }

    @media (min-width: ${values.DEVICESIZE.laptop}) {
      img {
        border-width: 5px;
        border-radius: 8px;
      }
    }
  }
`

export default function SliderdWorksWebsites() {
  const [currentInx, setCurrentInx] = useState(0)

  const onChangeSlide = currentIndex => {
    if (currentInx !== currentIndex) {
      setCurrentInx(currentIndex)
    }
  }

  const slideImges = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "gallery/works/web-services" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      file(relativePath: { eq: "icons/utils/macbook.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const settings = {
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    beforeChange: function (index) {
      onChangeSlide(index)
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
    ],
  }
  return (
    <SliderWrap>
      <BackgroundImgWeb>
        <div className="active-img-wrap">
          <Img
            fluid={
              slideImges.allFile.edges[currentInx].node.childImageSharp.fluid
            }
            alt={slideImges.allFile.edges[currentInx].node.base.split(".")[0]}
          />
        </div>
      </BackgroundImgWeb>
      <Slider {...settings}>
        {slideImges.allFile.edges.map(image => (
          <StyledItemContainer key={image.node.base.split(".")[0]}>
            <Img
              fluid={image.node.childImageSharp.fluid}
              alt={image.node.base.split(".")[0]}
            />
          </StyledItemContainer>
        ))}
      </Slider>
    </SliderWrap>
  )
}
