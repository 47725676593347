import React from "react"
import { Link } from "gatsby"
import SliderWorksWebServices from "../SingleComponents/SliderWorksWebServices"
import arrow from "../../images/icons/utils/arrow.svg"
import styled from "styled-components"
import values from "../../utils/values"
import {  useTranslation } from "gatsby-plugin-react-i18next"

const WorksWebsitesWrap = styled.div`
  margin: 20rem 0;
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  .slider-wrap {
    width: 100%;
    margin-top: 10rem;
  }

  .content-wrap {
    width: 100%;
    margin-bottom: 5rem;
  }

  @media (min-width: ${values.DEVICESIZE.laptopL}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .slider-wrap {
      width: 55%;
    }

    .content-wrap {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${values.DEVICESIZE.desktop}) {
    .slider-wrap {
      width: 60%;
    }
  }
`

export default function WorksWebServices() {
  const {t}= useTranslation()
  return (
    <WorksWebsitesWrap>
      <div className="slider-wrap">
        <SliderWorksWebServices />
      </div>
      <div className="section-content-wrap">
        <h2 className="fadeup">{t("Our Works: Websites and E-commerce")}</h2>
        <Link to="/service/" className="arrowlink fadeup fadeup-2">
          {t("SEE MORE ABOUT OUR SERVICE")}
          <img src={arrow} alt="arrow" />
        </Link>
      </div>
    </WorksWebsitesWrap>
  )
}
