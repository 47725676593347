import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import ColorThemeWrapper from "../components/Wrappers/ColorThemeWrapper"
import WorksWebServices from "../components/Works/WorksWebServices"
import WorksMobileApp from "../components/Works/WorksMobileApp"
import MainContentWrap from "../components/Wrappers/MainContentWrap"
import arrowWhite from "../images/icons/utils/arrowWhite.svg"
import {  useTranslation } from "gatsby-plugin-react-i18next"

const Works = () => {
  const {t} = useTranslation()
  
  return(
  <>
    <SEO title="Works" />
    <ColorThemeWrapper
      wrap1={<WorksWebServices />}
      wrap2={<WorksMobileApp />}
      wrap3={
        <MainContentWrap>
          <h2>{t("LETS CREATE YOUR PROJECT TOGETHER")}</h2>
          <Link to="/contacts/" className="arrowlink fadeup fadeup-2">
            {t("CONTACT US")}
            <img src={arrowWhite} alt="arrow" />
          </Link>
        </MainContentWrap>
      }
    />
  </>
)}

export default Works

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "works"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
