import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import BackgroundImgMobile from "./BackgroundImgWorksMobile"
import values from "../../utils/values"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderWrap = styled.div`
  width: 100%;
  margin: 0 auto;

  & > div {
    padding-top: 18%;
    padding-right: 1.5%;
    height: 410px;
    transform: translateX(7%);

    @media (min-width: ${values.DEVICESIZE.mobileL}) {
      padding-top: 14%;
    }

    @media (min-width: 600px) {
      padding-top: 20%;
    }

    @media (min-width: ${values.DEVICESIZE.laptop}) {
      height: 710px;
      transform: translate(10%, 11%);
    }
    @media (min-width: ${values.DEVICESIZE.laptopL}) {
      height: 850px;
      max-width: 900px;
      padding-top: 12%;
      padding-right: 1.5%;
      transform: translate(10%, 6%);
    }

    @media (min-width: ${values.DEVICESIZE.desktop}) {
      height: 1000px;
      width: 950px;
      padding-top: 10%;
      padding-right: 1%;
      transform: translate(10%, 5%);
    }
  }

  .slick-slider {
    width: 150px;
    margin: 0 auto;

    @media (min-width: ${values.DEVICESIZE.laptop}) {
      width: 250px;
    }

    @media (min-width: ${values.DEVICESIZE.laptopL}) {
      width: 310px;
    }
    @media (min-width: ${values.DEVICESIZE.desktop}) {
      width: 360px;
    }

    .slick-dots {
      padding: 0 50px;
      bottom: -13%;
      z-index: 4;
    }

    .slick-arrow {
      z-index: 5;
      width: 30px;
      height: 30px;
      top: 110%;

      :before {
        color: black;
        font-size: 30px;
      }

      &.slick-next {
        right: 6%;
      }

      &.slick-prev {
        left: 6%;
      }

      @media (min-width: ${values.DEVICESIZE.laptop}) {
        width: 40px;
        height: 40px;

        :before {
          font-size: 40px;
        }

        &.slick-next {
          right: 0;
        }

        &.slick-prev {
          left: 0;
        }
      }
    }
  }
`

const StyledItemContainer = styled.div`
  .gatsby-image-wrapper {
    height: 250px;

    @media (min-width: ${values.DEVICESIZE.laptop}) {
      height: 440px;
    }

    @media (min-width: ${values.DEVICESIZE.laptopL}) {
      height: 550px;
    }

    @media (min-width: ${values.DEVICESIZE.desktop}) {
      height: 630px;
    }
  }
`

export default function SliderdWorksMobile() {
  const slideImges = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "gallery/works/mobile-app" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          dots: false,
          // autoplay: false,
        },
      },
    ],
  }
  return (
    <SliderWrap>
      <BackgroundImgMobile>
        <Slider {...settings}>
          {slideImges.allFile.edges.map(image => (
            <StyledItemContainer key={image.node.base.split(".")[0]}>
              <Img
                fluid={image.node.childImageSharp.fluid}
                alt={image.node.base.split(".")[0]}
              />
            </StyledItemContainer>
          ))}
        </Slider>
      </BackgroundImgMobile>
    </SliderWrap>
  )
}
