import React from "react"
import { Link } from "gatsby"
import SliderWorksMobileApp from "../SingleComponents/SliderWorksMobileApp"
import arrow from "../../images/icons/utils/arrow.svg"
import styled from "styled-components"
// import values from "../../utils/values"
import {  useTranslation } from "gatsby-plugin-react-i18next"


const WorksWebsitesWrap = styled.div`
  margin-top: 10rem;
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .slider-wrap {
    width: 100%;
    position: relative;
  }

  .section-content-wrap {
    margin-bottom: 5rem;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .slider-wrap {
      width: 50%;
    }

    .section-content-wrap {
      width: 45%;
      margin-bottom: 0;
    }
  }
`

export default function WorksWebsites() {
  const {t}= useTranslation()
  return (
    <WorksWebsitesWrap>
      <div className="section-content-wrap">
        <h2 className="fadeup">{t("Mobile App and Branding")}</h2>
        <Link to="/service/" className="arrowlink fadeup fadeup-2">
          {t("TRY OUR SERVICE")}
          <img src={arrow} alt="arrow" />
        </Link>
      </div>
      <div className="slider-wrap">
        <SliderWorksMobileApp />
      </div>
    </WorksWebsitesWrap>
  )
}
