import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"

const BackgroundSection = ({ children }) => {
  const backgroundImg = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "icons/utils/macbook.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      fluid={backgroundImg.placeholderImage.childImageSharp.fluid}
      className="backgroundImage"
    >
      {children}
    </BackgroundImage>
  )
}

const BackgroundImgWeb = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`

export default BackgroundImgWeb
